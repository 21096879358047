/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Radio,
  Tag,
  Timeline,
  Typography,
} from "antd";
import Header from "components/header";
import PageWrapper from "components/pageWrapper";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getUserFeed, getUserProfile } from "utils/api";
import styles from "styles/pages/user-details.module.scss";
import ClapIcon from "components/clapIcon";
import { transformCompanyValues, useWindowSize } from "utils/utils";
import { ITEM_TYPES } from "utils/constants";
import Pie from "components/charts/pie";
import { shortnamesToUnicode } from "emojitsu";
const { Text } = Typography;

const Item = ({ data }) => {
  let renderType = null;

  switch (data.itemType) {
    case ITEM_TYPES.GIVEN:
      renderType = (
        <>
          <div>
            {`Gave `}
            <span className={styles.number}>{`${data.points} `}</span>
            <ClapIcon />
            {` to `}
            {data.recipients.map((rec, i) => (
              <>
                <a href={`/#/users/${rec._id}`}>{rec.name}</a>
                {i < data.recipients.length - 1 ? ", " : ""}
              </>
            ))}
          </div>

          <div className={styles.message}>
            <Text type="secondary" italic>
              {`"${data.message}"`}
            </Text>
          </div>
          {data.companyValues && data.companyValues.length > 0 && (
            <div className={styles.values}>
              {data.companyValues.map((c) => (
                <Tag bordered={false} color="green">
                  {shortnamesToUnicode(c)}
                </Tag>
              ))}
            </div>
          )}
        </>
      );
      break;
    case ITEM_TYPES.RECEIVED:
      renderType = (
        <>
          <div>
            {`Received `}
            <span className={styles.number}>{`${data.points} `}</span>
            <ClapIcon />
            {` from `}
            {data.giver._id === 'INIT' || data.giver._id === 'ADMIN' ? { INIT: 'initialization', ADMIN: 'an admin' }[data.giver._id] : <a href={`/#/users/${data.giver._id}`}>{data.giver.name}</a>}
          </div>
          <div className={styles.message}>
            <Text type="secondary" italic>
              {`"${data.message}"`}
            </Text>
          </div>
          {data.companyValues && data.companyValues.length > 0 && (
            <div className={styles.values}>
              {data.companyValues.map((c) => (
                <Tag bordered={false} color="green">
                  {shortnamesToUnicode(c)}
                </Tag>
              ))}
            </div>
          )}
        </>
      );
      break;
    case ITEM_TYPES.CLAIM:
      renderType = (
        <div>
          {`Claimed a reward:`}
          <Text mark> {data.reward.title}</Text>
          {` for `}
          <span className={styles.number}>{data.reward.amount}</span>
          <ClapIcon />
        </div>
      );
      break;
    default:
      renderType = <></>;
  }

  return <div key={data._id}>{renderType}</div>;
};

const UserDetails = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [companyValues, setCompanyValues] = useState(null);
  const [hideShowMore, setHideShowMore] = useState(false);
  const [itemType, setItemType] = useState(ITEM_TYPES.ALL);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = useWindowSize();

  const [feed, setFeed] = useState(null);
  const { id } = useParams();

  const getUserFeedData = (itemType = "ALL", after = null) =>
    getUserFeed({
      userId: id,
      feedType: itemType,
      ...(after ? { after: after } : {}),
    }).then((data) => {
      setFeed(
        after ? { ...feed, items: [...feed.items, ...data.items] } : data
      );
      if (data.items && data.items.length < 20) {
        setHideShowMore(true);
      }
    });

  useEffect(() => {
    //get user profile
    getUserProfile({ userId: id }).then((data) => {
      setUserProfile(data);
      setCompanyValues(transformCompanyValues(data.stats.companyValues));
    });
    const currentType = searchParams.get("type") || "ALL";
    setItemType(currentType);
    getUserFeedData(currentType);
  }, [id]);

  const onChangeType = ({ target: { value } }) => {
    setItemType(value);
    getUserFeedData(value);
    searchParams.set("type", value);
    setSearchParams(searchParams);
  };

  const showMore = () => {
    getUserFeedData(itemType, feed.items[feed.items.length - 1]._id);
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <div className={styles.wrapper}>
          <div className={styles.leftWrapper}>
            <div className={styles.leftInner}>
              <Avatar size={96} src={userProfile?.profilePicture} />
              <h1 className={styles.name}>{userProfile?.name}</h1>
              <div className={styles.stats}>
                <div className={styles.statElem}>
                  {`Received:`}{" "}
                  <span className={styles.number}>{`${userProfile?.stats.receivedPoints || "0"
                    } `}</span>{" "}
                  <ClapIcon />
                </div>
                <Divider type="vertical" />
                <div className={styles.statElem}>
                  {`Given:`}{" "}
                  <span className={styles.number}>{`${userProfile?.stats.givenPoints || "0"
                    } `}</span>{" "}
                  <ClapIcon />
                </div>
                <Divider type="vertical" />
                <div className={styles.statElem}>
                  {`Redeemable:`}{" "}
                  <span className={styles.number}>{`${userProfile?.stats.redeemablePoints || "0"
                    } `}</span>{" "}
                  <ClapIcon />
                </div>
              </div>
              <div>
                {companyValues && companyValues.length > 0 && (
                  <Pie
                    simpleLabels
                    data={companyValues}
                    width={size.width < 960 ? size.width - 72 : 380}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.rightWrapper}>
            <div className={styles.rightTitle}>
              <h2 className={styles.feedTitle}>Feed</h2>
              <div>
                <Radio.Group
                  size="small"
                  options={Object.keys(ITEM_TYPES).map((e) => ({
                    label: `${e.substring(0, 1)}${e
                      .substring(1, e.length)
                      .toLowerCase()}`,
                    value: e,
                  }))}
                  onChange={onChangeType}
                  value={itemType}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </div>

            {!userProfile && <LoadingOutlined />}
            <Timeline
              className={styles.timeline}
              mode={"left"}
              items={feed?.items?.map((item) => {
                return {
                  id: item?._id,
                  key: item?._id,
                  color:
                    item.itemType === ITEM_TYPES.GIVEN ? "#0d5181" : "green",
                  label: (
                    <span className={styles.date}>
                      {new Date(item.date).toLocaleString("en-US")}
                    </span>
                  ),
                  children: <Item data={item} key={item._id} />,
                };
              })}
            />
            {!hideShowMore && (
              <div className={styles.showmore}>
                <Button type="link" onClick={showMore}>
                  Show more
                </Button>
              </div>
            )}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default UserDetails;
