import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/global.css';
import App from './App';
// import reportWebVitals from "./reportWebVitals";
import { HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import { ROLLBAR_ACCESS_TOKEN } from 'utils/constants';
import { isLocalhost } from 'utils/utils';

const rollbarConfig = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  environment: isLocalhost() ? 'development' : 'production',
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1f3a45',
          borderRadius: 8,
          fontFamily: "'silka', sans-serif",
          boxShadow: 'none',
          colorLink: '#158c59',
          controlHeight: 40,
          fontSize: 16,
        },
      }}
    >
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    </ConfigProvider>
  </HashRouter>
);

// reportWebVitals();
